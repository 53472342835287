.container {
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 1100px;
  margin: 20px;
  padding: 10px 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.blank {
  background: #e4e7eb;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.zone {
  display: flex;
  justify-content: center;
  flex: 1;
  width: 100%;
  background: #f7f8fc;
}
