.container {
  width: 420px;
  position: sticky;
  box-sizing: border-box;
  top: 0;
  padding: 10px;
  border-right: 3px solid #efefef;
  height: 100vh;
  background: #fff;
}

.item {
  color: rgb(150, 150, 150);
  padding: 1rem;
  border: 2px dashed #fff;
}

.item:hover {
  border-radius: 10px;
  cursor: grab;
  border: 2px dashed rgba(143, 142, 199, 0.5);
}
