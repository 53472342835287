.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: hsl(210, 21%, 85%);
  height: 100vh;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  max-width: 400px;
  min-height: 400px;
}
